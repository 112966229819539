import { $ } from "~/zeus";
import { FallbackLocaleInputType } from "~/zeus";
import { mediaFragment } from "~/zeus/fragments";
import { typedGql } from "~/zeus/typedDocumentNode";

const BannerSectionQuery = typedGql("query")({
  GetBannerSection: [
    {
      locale: $("locale", "LocaleInputType"),
      fallbackLocale: FallbackLocaleInputType.zh,
      limit: $("limit", "Int"),
      where: $("where", "Banner_where"),
      sort: $("sort", "String"),
      page: $("page", "Int"),
      slug: $("slug", "String"),
    },
    {
      totalDocs: true,
      totalPages: true,
      docs: {
        id: true,
        name: true,
        bannerStartDate: true,
        bannerEndDate: true,
        _locations: true,
        region: true,
        desktop: [{}, mediaFragment],
        mobile: [{}, mediaFragment],
        link: true,
        external: true,
      },
    },
  ],
});

export default BannerSectionQuery;
